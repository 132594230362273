<template>
    <div>
        <v-dialog v-model="isOrVerificationOpen" width="auto">
            <v-card>
                <v-col style="font-weight: bold;">
                    Ne plus afficher <span style="color:red">{{ selectedOrData['N° RAP COMP'] }}</span> sur la
                    web-application ?
                </v-col>
                <v-col>
                    <v-row justify="center" align="center">
                        <v-col cols="6" align=center>
                            <v-btn color="#4CAF50" style="color:white" @click="valideSuppresion()">
                                Oui
                            </v-btn>
                        </v-col>
                        <v-col cols="6" align=center>
                            <v-btn color="#EF5350" style="color:white" @click="isOrVerificationOpen = false">
                                Non
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-card>
        </v-dialog>
        <v-dialog v-model="isOrModalOpen" fullscreen persistent>
            <v-card style="overflow: auto; max-height: 90vh;">
                <sav-form @close="closeModal()" :getOrListeAll="getOrListeAll" :itemsData="itemsData"
                    :technicienListe="technicienListe" :agenceListe="agenceListe" :clientListe="clientListe"
                    :checkListe="checkListe" :parcListe="parcListe" :stockListe="stockListe" :FormData="savForm"
                    :optionsObj="optionsObj" :user="getUserInformation"></sav-form>
            </v-card>
        </v-dialog>
        <v-card class="pa-2 mx-1">
            <v-col class="pb-2">
                <h3>Liste des interventions</h3>
                <v-row align="center" justify="center">
                    <v-col class="" style="text-transform: uppercase;">
                        <v-select bg-color="#ECEFF1" class="mt-2" label="Technicien(s)" variant="outlined" closable-chips chips
                            :items="techListe" multiple v-model="selectedTech" hide-details></v-select>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn variant="flat" color="rgb(92, 107, 192)" style="color:white"
                            @click="openDialog(undefined)">Créer un
                            OR
                        </v-btn>
                        <v-btn icon="mdi-refresh" density="compact" style="font-size:20px" variant="flat" size="40"
                            @click="getOrListeAll" class="ml-3"></v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" class="pb-1 pt-0">
                <v-text-field bg-color="#ECEFF1" variant="outlined" hide-details v-model="search" label="Recherche"
                    clearable></v-text-field>
            </v-col>
            <v-col class="py-1">
                <v-select variant="outlined" bg-color="#ECEFF1" label="Filtrer sur les OR..." multiple hide-details
                    v-model="orFilterByStatut" :items="listeStatutToFilter" closable-chips chips></v-select>
            </v-col>
            <v-col class="pt-1">
                <Vue3EasyDataTable :sort-by="'date_debut_location_TIMESTAMP'" :sort-type="'desc'"
                    table-class-name="customize-table" ref="dataTable" :headers="get_tab_header"
                    :items="get_or_liste_filtered" style="overflow: auto" :table-height="windowHeight * 0.55"
                    rows-per-page-message="Ligne par colonne" rows-of-page-separator-message="total"
                    empty-message="Aucune intervention trouvé" @click-row="showOr">
                    <template #item-Status_devis="item">
                        <div align=center class="pa-1">
                            <v-chip v-if="item['Status devis'] == 'Signé'" color="white"
                                style="font-weight: bold; background-color: green;">
                                {{ item['Status devis'] }}
                            </v-chip>
                            <v-chip v-else-if="item['Status devis'] == 'en cours'" color="white"
                                style="font-weight: bold; background-color: #FFA726;">
                                {{ item['Status devis'] }}
                            </v-chip>
                            <v-chip v-else style="font-weight: bold;">
                                {{ item['Status devis'] }}
                            </v-chip>
                        </div>
                    </template>
                    <template #item-DATE-RAPPORT="item">
                        {{ formatDate(item['DATE-RAPPORT'], 'DD/MM/YYYY') }}
                    </template>
                    <template #item-date_debut_location_TIMESTAMP="item">
                        <div v-if="item.date_debut_location_TIMESTAMP">
                            {{ formatDate(parseInt(item.date_debut_location_TIMESTAMP), 'DD/MM/YYYY') }}
                        </div>
                    </template>
                    <template #item-date_fin_location_TIMESTAMP="item">
                        <div v-if="item.date_fin_location_TIMESTAMP">
                            {{ formatDate(parseInt(item.date_fin_location_TIMESTAMP), 'DD/MM/YYYY') }}
                        </div>
                    </template>
                    <template #item-delete="item">
                        <div class="customize-header" align=center>
                            <v-row justify="center" align=center>
                                <v-col>
                                    <v-btn variant="flat" color="#E57373" style="font-size: 14px;z-index:5"
                                        @click="deleteOr(item)">
                                        <v-icon color="white">mdi-delete</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </template>
                    <!-- <template #item-show="item">
                        <div class="pa-1" style="">
                            <v-btn icon="mdi-open-in-app" size="35"
                                style="border-radius: 0px;font-size: 15px; width: 100%;color:white" color="#7986CB"
                                @click="showOr(item)">
                            </v-btn>
                        </div>
                    </template> -->
                    <template #item-Statut="item">
                        <div class="pa-1" style="">
                            <!-- {{ item.Table }} -->
                            <div v-if="item.Statut == 140">
                                <v-card color="#EF9A9A" class="px-4 py-1" align=center variant="flat"
                                    style="font-weight: bold; color: white; border: solid 1px grey;">
                                    Cloturé
                                </v-card>
                            </div>
                            <div v-else>
                                <v-card :color="item.retour == 'oui' ? '#006064' : '#4CAF50'" class="px-4 py-1"
                                    align=center variant="flat"
                                    style="font-weight: bold; color: white; border: solid 1px grey;">
                                    En cours <span v-if="item.retour == 'oui'"> RETOUR </span>
                                </v-card>
                            </div>
                        </div>
                    </template>
                </Vue3EasyDataTable>
            </v-col>
        </v-card>
    </div>
</template>

<script>

import savHooks from '../hooks/sav'
import SavForm from './sav-form.vue'
import Vue3EasyDataTable from "vue3-easy-data-table";
import engine from '../core/DataApi'
import moment from 'moment'

export default {
    setup() {
        const { clientListe, parcListe, stockListe, savForm, orListe, technicienListe, agenceListe, getOrListeAll, getUserInformation, checkListe, tableHeader, techListe } = savHooks()
        return { clientListe, parcListe, stockListe, savForm, orListe, technicienListe, agenceListe, getOrListeAll, getUserInformation, checkListe, tableHeader, techListe };
    },
    data() {
        return {
            selectedTech: [],
            orFilterByStatut: ['130'],
            listeStatutToFilter: [
                { title: 'Cloturé', value: '140' },
                { title: 'En cours', value: '130' },
            ],
            optionsObj: undefined,
            selectedOrData: undefined,
            isOrVerificationOpen: false,
            itemsData: undefined,
            search: "",
            windowHeight: window.innerHeight,
            isOrModalOpen: false
        }
    },
    mounted() {

        let query = this.$route.query
        if (query.search)
            this.search = query.search


        this.onResize()
        this.getOrOptions()
        this.selectedTech = [this.getUserInformation.user_name]
    },
    computed: {
        get_tab_header() {
            let tab_header = this.tableHeader
            tab_header = tab_header.sort((a, b) => a.index - b.index);
            // let listFromServ = this.tableHeader
            let list = [
                // {
                //     key: 'DATE RAPPORT',
                //     label: 'Date du rapport'
                // }, 
                //  {
                //     key: 'N° RAP COMP',
                //     label: 'Numéro'
                // }, {
                //     key: 'societeliv',
                //     label: 'Société'
                // }, {
                //     key: 'N° MATR INT',
                //     label: 'Matricule'
                // }, {
                //     key: 'MARQUE MACHINE',
                //     label: 'Marque'
                // }, {
                //     key: 'villeliv',
                //     label: 'Ville'
                // }, 
                {
                    key: 'show',
                    label: 'Visualiser'
                },
                {
                    key: 'Statut',
                    label: 'Statut'
                },
                {
                    key: 'delete',
                    label: ''
                }
            ];
            for (let i = 0; i < list.length; i++) {
                tab_header.push({
                    text: list[i].label,
                    value: list[i].key,
                    sortable: true
                });
            }

            return tab_header;
        },
        get_or_liste_filtered() {
            let liste = []

            if (this.search == undefined || this.search.length == 0) {
                liste = this.orListe

                for (let i = 0; i < this.orListe.length; i++)
                    liste[i]['DATE-RAPPORT'] = this.orListe[i]['DATE RAPPORT']
            }
            else {
                for (let i = 0; i < this.orListe.length; i++) {
                    let inter = this.orListe[i]
                    liste[i]['DATE-RAPPORT'] = this.orListe[i]['DATE RAPPORT']
                    let key = inter['N° RAP COMP'] + inter['N° MATR INT'] + inter['societeliv'] + inter['num_contrat'] + inter['societeliv']

                    if (key.toUpperCase().includes(this.search.toUpperCase()))
                        liste.push(inter)
                }
            }

            // return liste.sort((a, b) => b.INDEX - a.INDEX).filter((val) => this.orFilterByStatut.includes(val.Statut) && this.selectedTech.includes(val["Techniciens_Nom(1)"]))
            return liste.filter((val) => this.orFilterByStatut.includes(val.Statut) && this.selectedTech.includes(val["Techniciens_Nom(1)"]))
        }
    },
    methods: {
        getOrOptions() {
            let that = this
            engine.get_or_options(function (data) {
                if (data.code == 0) {
                    let tmpTab = data.data.data.map((val) => val.fieldData)
                    that.optionsObj = {}
                    for (let i = 0; i < tmpTab.length; i++) {
                        that.optionsObj[tmpTab[i].Name] = tmpTab[i].Value
                        if (tmpTab[i].Value == 'true')
                            that.optionsObj[tmpTab[i].Name] = true
                        if (tmpTab[i].Value == 'false')
                            that.optionsObj[tmpTab[i].Name] = false
                    }
                }
            })
        },
        valideSuppresion() {
            let that = this
            if (this.selectedOrData.Table == "SAV_FICHE") {
                engine.delete_or_by_id(this.selectedOrData["N° RAP COMP"], function () {
                    that.getOrListeAll()
                    that.selectedOrData = undefined
                })
            } else {
                engine.delete_or2_by_id(this.selectedOrData["N° RAP COMP"], function () {
                    that.getOrListeAll()
                    that.selectedOrData = undefined
                })
            }
            this.isOrVerificationOpen = false
        },
        showOr(orData) {
            if (this.isOrVerificationOpen == false)
                this.openDialog(orData)
        },
        deleteOr(orData) {
            this.isOrVerificationOpen = true
            this.selectedOrData = orData
        },
        closeModal() {
            this.isOrModalOpen = false
            // this.getOrListeAll()
        },
        onResize() {
            this.windowHeight = window.innerHeight
        },
        openDialog(data) {
            this.itemsData = data
            this.isOrModalOpen = true
        },
        formatDate(date, form) {
            let tmpDate = moment(date).format(form)
            if (tmpDate == 'Invalid date')
                tmpDate = undefined
            return tmpDate
        },
    },
    components: {
        SavForm,
        Vue3EasyDataTable
    }
}
</script>

<style>
.customize-table {
    --easy-table-border: 1px solid lightgrey;
    --easy-table-row-border: 1px solid lightgrey;

    --easy-table-header-font-size: 14px;
    --easy-table-header-height: 50px;
    --easy-table-header-font-color: black;
    --easy-table-header-background-color: #ECEFF1;

    /* --easy-table-header-item-padding: 10px 15px; */

    --easy-table-body-even-row-font-color: #fff;
    --easy-table-body-even-row-background-color: #4c5d7a;

    --easy-table-body-row-font-color: black;
    --easy-table-body-row-background-color: #ECEFF1;
    --easy-table-body-row-height: 50px;
    --easy-table-body-row-font-size: 12px;

    --easy-table-body-row-hover-font-color: black;
    --easy-table-body-row-hover-background-color: lightgray;

    /* --easy-table-body-item-padding: 10px 15px; */

    --easy-table-footer-background-color: #ECEFF1;
    --easy-table-footer-font-color: black;
    --easy-table-footer-font-size: 14px;
    --easy-table-footer-padding: 0px 10px;
    --easy-table-footer-height: 50px;

    --easy-table-rows-per-page-selector-width: 70px;
    --easy-table-rows-per-page-selector-option-padding: 10px;
    --easy-table-rows-per-page-selector-z-index: 1;


    /* --easy-table-scrollbar-track-color: #ECEFF1;
    --easy-table-scrollbar-color: #ECEFF1;
    --easy-table-scrollbar-thumb-color: #4c5d7a;
    --easy-table-scrollbar-corner-color: #ECEFF1; */

    --easy-table-loading-mask-background-color: #ECEFF1;
}
</style>